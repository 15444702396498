var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"header pb-6 d-flex align-items-center"},[_c('span',{staticClass:"mask bg-white opacity-8"}),_c('b-container',{staticClass:"d-flex",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"sm":"12","md":"auto"}},[_c('div',{staticClass:"icon icon-shape rounded-circle shadow bg-primary text-white",on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"fa fa-arrow-left"})])]),_c('b-col',{staticClass:"mt-4",attrs:{"sm":"12","md":"auto"}},[(_vm.hackathon_id)?_c('h1',{staticClass:"text-primary display-3"},[_vm._v(" "+_vm._s(_vm.hackathon.name)+" "),_vm._v(" Ideas ")]):_vm._e()]),_c('b-col',{staticClass:"text-right mt-4 pt-3"},[_c('h2',[(_vm.hackathon.is_creator || _vm.hackathon.is_manager)?_c('router-link',{attrs:{"to":{
                  path: ("/hackathon/" + (encodeURIComponent(
                    _vm.hackathon_id
                  )) + "/timeline"),
                }}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()],1)])],1)],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"3"}},[_c('b-form',{staticClass:"navbar-search form-inline navbar-search-light",attrs:{"id":"navbar-search-main"},on:{"submit":function($event){$event.preventDefault();return _vm.on_search_submit.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-0"},[_c('b-input-group',{staticClass:"input-group-alternative input-group-merge"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fas fa-search"})])]),_c('b-form-input',{attrs:{"placeholder":"Search","type":"text"},on:{"keyup":_vm.on_keyup_search},model:{value:(_vm.search.search_term),callback:function ($$v) {_vm.$set(_vm.search, "search_term", $$v)},expression:"search.search_term"}}),_c('input',{ref:"search_form_submit",attrs:{"type":"submit","hidden":""}})],1)],1),_c('Transition',[(_vm.search.is_loading)?_c('i',{staticClass:"fas fa-spinner fa-spin ml--4 mt-2 text-muted"}):_vm._e()]),_c('button',{staticClass:"close",attrs:{"type":"button","data-action":"search-close","data-target":"#navbar-search-main","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])],1)],1),(_vm.filtered_hackathon_ideas.length > 0)?_c('b-col',{staticClass:"text-right"},[_c('el-tooltip',{attrs:{"content":"Submit an Idea","placement":"bottom"}},[_c('router-link',{staticClass:"icon icon-shape rounded-circle shadow bg-primary text-white",attrs:{"to":{
                    name: 'HackathonCrIdea',
                    params: {
                      hackathon_id: _vm.hackathon_id,
                    },
                  }}},[_c('i',{staticClass:"fa fa-plus"})])],1)],1):_vm._e()],1),(_vm.filtered_hackathon_ideas.length > 0)?_c('b-row',_vm._l((_vm.filtered_hackathon_ideas),function(idea){return _c('HackathonListIdeaCard',{key:idea.id,attrs:{"id":idea.id,"hackathon_id":_vm.hackathon_id,"name":idea.name,"description":idea.description,"likes":idea.number_of_likes,"liked_id":idea.liked_id,"is_creator":idea.is_creator,"team":idea.team,"is_part_of_team":_vm.is_part_of_team},on:{"liked":function($event){idea.number_of_likes++},"new_like_id":function (id) {
                  idea.liked_id = id;
                },"unliked":function($event){idea.number_of_likes--}}})}),1):(
              _vm.filtered_hackathon_ideas.length < 1 &&
              _vm.hackathon_ideas.length > 0
            )?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h3',{staticClass:"text-muted"},[_vm._v("No ideas found matching your search")])])],1):(!_vm.$apollo.loading)?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h3',[_vm._v(" This hackathon doesn't have any ideas submitted yet, submit the first idea. ")]),_c('el-tooltip',{attrs:{"content":"Submit an Idea","placement":"bottom"}},[_c('router-link',{staticClass:"icon icon-shape rounded-circle shadow bg-primary text-white",attrs:{"to":{
                    name: 'HackathonCrIdea',
                    params: {
                      hackathon_id: _vm.hackathon_id,
                    },
                  }}},[_c('i',{staticClass:"fa fa-plus"})])],1)],1)],1):_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('Transition',[_c('h1',[_c('i',{staticClass:"fas fa-spinner fa-spin ml--4 mt-2 text-muted"})])])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }