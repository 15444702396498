<template>
  <div>
    <div>
      <div class="header pb-6 d-flex align-items-center">
        <!-- Mask -->
        <span class="mask bg-white opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex">
          <b-row>
            <b-col sm="12" md="auto" class="mt-4">
              <div
                @click="$router.back()"
                class="icon icon-shape rounded-circle shadow bg-primary text-white"
              >
                <i class="fa fa-arrow-left"></i>
              </div>
            </b-col>
            <b-col sm="12" md="auto" class="mt-4">
              <h1 class="text-primary display-3" v-if="hackathon_id">
                {{ hackathon.name }}
                <!-- {{ hackathon.name.toLowerCase().endsWith("hackathon") ? ' ':' hackathon ' }} -->
                Ideas
              </h1>
            </b-col>
            <b-col class="text-right mt-4 pt-3">
              <h2>
                <router-link
                  v-if="hackathon.is_creator || hackathon.is_manager"
                  :to="{
                    path: `/hackathon/${encodeURIComponent(
                      hackathon_id
                    )}/timeline`,
                  }"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
              </h2>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid class="mt--6">
        <!-- Ideas -->

        <b-row class="mt-4">
          <b-col sm="12">
            <b-row class="mb-4">
              <!-- Search -->
              <b-col sm="3" class="pr-0">
                <b-form
                  class="navbar-search form-inline navbar-search-light"
                  id="navbar-search-main"
                  @submit.prevent="on_search_submit"
                >
                  <b-form-group class="mb-0">
                    <b-input-group
                      class="input-group-alternative input-group-merge"
                    >
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-search"></i
                        ></span>
                      </div>
                      <b-form-input
                        placeholder="Search"
                        type="text"
                        v-model="search.search_term"
                        @keyup="on_keyup_search"
                      >
                      </b-form-input>

                      <input type="submit" ref="search_form_submit" hidden />
                    </b-input-group>
                  </b-form-group>
                  <Transition>
                    <i
                      v-if="search.is_loading"
                      class="fas fa-spinner fa-spin ml--4 mt-2 text-muted"
                    ></i>
                  </Transition>
                  <button
                    type="button"
                    class="close"
                    data-action="search-close"
                    data-target="#navbar-search-main"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </b-form>
              </b-col>

              <b-col
                class="text-right"
                v-if="filtered_hackathon_ideas.length > 0"
              >
                <el-tooltip content="Submit an Idea" placement="bottom">
                  <router-link
                    :to="{
                      name: 'HackathonCrIdea',
                      params: {
                        hackathon_id: hackathon_id,
                      },
                    }"
                    class="icon icon-shape rounded-circle shadow bg-primary text-white"
                  >
                    <i class="fa fa-plus"></i>
                  </router-link>
                </el-tooltip>
              </b-col>
            </b-row>
            <b-row v-if="filtered_hackathon_ideas.length > 0">
              <HackathonListIdeaCard
                v-for="idea in filtered_hackathon_ideas"
                :key="idea.id"
                :id="idea.id"
                :hackathon_id="hackathon_id"
                :name="idea.name"
                :description="idea.description"
                :likes="idea.number_of_likes"
                :liked_id="idea.liked_id"
                :is_creator="idea.is_creator"
                :team="idea.team"
                :is_part_of_team="is_part_of_team"
                @liked="idea.number_of_likes++"
                @new_like_id="
                  (id) => {
                    idea.liked_id = id;
                  }
                "
                @unliked="idea.number_of_likes--"
              />
            </b-row>
            <b-row
              v-else-if="
                filtered_hackathon_ideas.length < 1 &&
                hackathon_ideas.length > 0
              "
            >
              <b-col class="text-center">
                <h3 class="text-muted">No ideas found matching your search</h3>
              </b-col>
            </b-row>

            <b-row v-else-if="!$apollo.loading">
              <b-col class="text-center">
                <h3>
                  This hackathon doesn't have any ideas submitted yet, submit
                  the first idea.
                </h3>
                <el-tooltip content="Submit an Idea" placement="bottom">
                  <router-link
                    :to="{
                      name: 'HackathonCrIdea',
                      params: {
                        hackathon_id: hackathon_id,
                      },
                    }"
                    class="icon icon-shape rounded-circle shadow bg-primary text-white"
                  >
                    <i class="fa fa-plus"></i>
                  </router-link>
                </el-tooltip>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col class="text-center">
                <Transition>
                  <h1>
                    <i class="fas fa-spinner fa-spin ml--4 mt-2 text-muted"></i>
                  </h1>
                </Transition>
              </b-col>
            </b-row>
            <!-- <div v-for="idea in ideasList" :key="idea">
                <hackathon-ideas :ideas="idea"></hackathon-ideas>
              </div> -->
            <!-- <div v-if="int_hackathon">
                            <hackathon-idea-list-item
                                v-for="ideaItem in ideasList"
                                :key="ideaItem.id"
                                :idea="ideaItem"
                                :maxSize="maxSize"
                                :user="int_user"
                                :hackathon_id="int_hackathon.id"
                            ></hackathon-idea-list-item>
                        </div>
                        <div v-else-if="!$apollo.loading">
                            <h3>Error, please reload the page</h3>
                        </div> -->
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce";

// Components
import HackathonListIdeaCard from "@/views/Components/Hackathon/Idea/ListIdeaCard.vue";

// Queries
import { GET_HACKATHON_IDEAS } from "@/graphql/queries";

export default {
  name: "ViewHackathon",
  components: {
    HackathonListIdeaCard,
  },
  apollo: {
    get_all_hackathon_ideas: {
      query: GET_HACKATHON_IDEAS,
      result(res) {
        if (res.data && res.data.allHackathonIdeaIdea) {
          let flat_res = graph_utils.flatten_objects_recursive(
            graph_utils.apollo_to_obj_recursive(res)
          );
          this.hackathon_ideas = [];
          flat_res.data__allHackathonIdeaIdea.forEach((el) => {
            let hackathon_idea = {
              id: el.id,
              name: el.name,
              description: el.description,
              detail_description: el.detailDescription,
              number_of_likes: el.numberOfLikes,
              created: new Date(el.created),
              is_creator: el.isCreator,
              liked_id: null,
            };
            // if (el.likes.length > 0) {
            //     hackathon_idea.liked_id = el.likes[0].id;
            // }
            if ("team__id" in el) {
              hackathon_idea.team = {
                member_count: el.team__totalMembers,
                max_members: el.hackathon__maxTeamSize,
                is_member: el.team__isMember,
              };
              if (el.team__isMember) {
                this.is_part_of_team = true;
              }
            } else {
              hackathon_idea.team = {
                member_count: 0,
                max_members: el.hackathon__maxTeamSize,
                is_member: false,
              };
            }
            this.hackathon_ideas.push(hackathon_idea);
          });
          this.filtered_hackathon_ideas = this.hackathon_ideas;
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          hackathon_id: this.hackathon_id,
        };
      },
      update(data) {
        this.apollo_data.get_all_hackathon_ideas = data;
      },
      skip: false,
    },
  },
  data() {
    return {
      apollo_data: {
        get_all_hackathon_ideas: null,
      },
      hackathon: {
        name: null,
        description: null,
        detail_description: null,
        is_participant: false,
        is_manager: false,
        is_creator: false,
      },

      hackathon_ideas: [],
      filtered_hackathon_ideas: [],
      is_part_of_team: false,
      modals: {
        register_confirm_modal: {
          loading: false,
          success: false,
        },
      },
      search: {
        search_term: "",
        is_loading: false,
      },
    };
  },
  computed: {
    hackathon_id() {
      return this.$route.params.hackathon_id; // Retrieve hackathon_id from route params
    },
  },
  methods: {
    // Search Functionality

    on_keyup_search() {
      this.search.is_loading = true;
      this.debounced_on_keyup_search();
    },

    debounced_on_keyup_search: debounce(function () {
      this.$refs.search_form_submit.click();
    }, 1000),
    on_search_submit() {
      if (this.search.search_term === "") {
        this.filtered_hackathon_ideas = this.hackathon_ideas;
        setTimeout(() => {
          this.search.is_loading = false;
        }, 500);
      } else {
        this.filtered_hackathon_ideas = this.filter_hackathon_ideas(
          this.search.search_term,
          this.hackathon_ideas
        );
        this.search.is_loading = false;
      }
    },

    filter_hackathon_ideas(search_term, in_array) {
      let out_array = in_array.filter((item) => {
        if (item.name === undefined) {
          item.name = "";
        }
        if (item.detail_description === undefined) {
          item.detail_description = "";
        }

        return (
          item.name.toLowerCase().includes(search_term.toLowerCase()) ||
          item.description.toLowerCase().includes(search_term.toLowerCase())
        );
      });
      return out_array;
    },

    // getUser() {
    //   this.int_user = utils.deepcopy(this.$store.getters.getUser);
    // },
  },
};
</script>

<style></style>
